/**
 * This contains models for Client (customer) objects.
 */

/*
 * The different add-on modules of SummIT Security that a Client can be licensed for.
 * Ensure changes to this enum are reflected in the corresponding enum in the back-end.
 */
export enum License {
    AI_TPRM_SERVICE_ASSESSMENT = 'AI TPRM Service Assessment',
    AI_TPRM_SERVICE_ASSESSMENT_DTECKT = 'AI TPRM Service Assessment Dteckt', // A Client cannot have this license alone, they must also have AI_TPRM_SERVICE_ASSESSMENT. This is considered an add-on to AI_TPRM_SERVICE_ASSESSMENT.
}

/**
 * On the back-end this model is simply called "Client" and while I normally prefer model names to match when they are defined in multiple places,
 * I'm leaving it as-is in the front-end code since "Client" is such a generic word.
 */
export interface ClientDetailsModel {
    client_id: string;
    client_name: string;
    licenses: License[]; // This will be an empty array for Clients that only have the base package of SummIT Security features. It is only populated for add-on modules
}
