import type { JSX } from 'react';
import { Toast as BootstrapToast, ToastBody, ToastHeader } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';

import styles from './Toast.module.css';

interface ToastProps {
    variant: 'success' | 'failure';
    clearToast: () => void;
    autoHide?: boolean;
    header: JSX.Element;
    body: JSX.Element;
}

export const Toast = (props: ToastProps): JSX.Element => {
    return (
        <BootstrapToast className={styles.toast} onClose={props.clearToast} delay={3000} autohide={props.autoHide}>
            <ToastHeader className={props.variant === 'success' ? styles.toastHeaderSuccess : styles.toastHeaderFailure}>{props.header}</ToastHeader>
            <ToastBody>{props.body}</ToastBody>
        </BootstrapToast>
    );
};

interface TextToastProps {
    variant: 'success' | 'failure';
    clearToast: () => void;
    autoHide?: boolean;
    text: string;
}

export const TextToast = (props: TextToastProps): JSX.Element => {
    return (
        <Toast
            variant={props.variant}
            clearToast={props.clearToast}
            autoHide={props.autoHide}
            header={
                <Text color={props.variant === 'success' ? 'darkGreen' : 'red'} variant="Text2" noStyles>
                    {props.variant === 'success' ? 'Success!' : 'Failure!'}
                </Text>
            }
            body={<Text variant="Text3">{props.text}</Text>}
        />
    );
};

interface LinkButtonToastProps {
    variant: 'success' | 'failure';
    clearToast: () => void;
    autoHide?: boolean;
    text: string;
    linkButtonText: string;
    linkButtonTo: string;
}

export const LinkButtonToast = (props: LinkButtonToastProps): JSX.Element => {
    return (
        <Toast
            variant={props.variant}
            clearToast={props.clearToast}
            autoHide={props.autoHide}
            header={
                <Text color={props.variant === 'success' ? 'darkGreen' : 'red'} variant="Text2" noStyles>
                    {props.variant === 'success' ? 'Success!' : 'Failure!'}
                </Text>
            }
            body={
                <>
                    <Text variant="Text3" color={props.variant === 'failure' ? 'red' : undefined}>
                        {props.text}
                    </Text>
                    <Link size="sm" to={props.linkButtonTo}>
                        {props.linkButtonText}
                    </Link>
                </>
            }
        />
    );
};
